var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};
var site = site || {};

site.direction = site.direction || {};
var generic = generic || {};

(function ($) {
  $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
    var $shadesContainer = $('.js-product-brief-shades-v1', $(this));
    var $swatchList = $('.js-product-brief-shades__grid', $shadesContainer);

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.productBriefShadesV1.selectSku($swatchList, skuBaseId);
    $swatchList.resize();
  });

  $(document).on('product.init', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v1', $(this));
    var $swatchList = $('.js-product-brief-shades__grid', $shadesContainer);

    if ($swatchList.length < 1) {
      return null;
    }
    Drupal.behaviors.productBriefShadesV1.init($swatchList);
    var text = $(this).find('.js-shade-updating-message-count').text().split(':')[0];
    var shadeCount = $(this).find(
      $('.js-sku-categories-content').find('.js-product-brief-shades-item')
    ).length;

    text += ':' + shadeCount;
    if ($(this).parent().hasClass('js-quickshop__container')) {
      $swatchList.attr('aria-labelledby', 'js-shade-updating-message-count--' + shadeCount);
      $swatchList
        .parent()
        .find('.js-shade-updating-message-count')
        .attr('id', 'js-shade-updating-message-count--' + shadeCount);
    }
    $(this).find('.js-shade-updating-message-count').empty().append(text);
  });

  $(document).on('inv_status_display:updated', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v1', $(this));
    var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

    $swatchLists.each(function () {
      var $swatchList = $(this);
      var $swatches = $('.js-product-brief-shades-item-link', $swatchList);

      $swatches.each(function () {
        var $swatchLink = $(this);
        var skuBaseId = $swatchLink.data('skuBaseId');
        var skuData = prodcat.data.getSku(skuBaseId);

        Drupal.behaviors.productBriefShadesV1.inventoryCheck($swatchLink, skuData);
      });
    });
  });

  // Display default shade pre-selected in cross sell section
  $(document).on('inv_status_data:updated', '.js-product', function () {
    var $shadesContainer = $('.js-product-brief-shades-v1', $(this));
    var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

    $swatchLists.each(function () {
      var $swatchList = $(this);

      Drupal.behaviors.productBriefShadesV1.selectFirstSku($swatchList);
    });
  });

  $(document).on('grid.activeFilter', function (e, activeFilters) {
    var $swatchList = $('.js-product:visible .js-product-brief-shades-item', $(this));

    if (Drupal.behaviors.productBriefShadesV1.useGridLayout) {
      Drupal.behaviors.productBriefShadesV1.rebuildShadesGridOnActiveFilter(
        $swatchList,
        activeFilters
      );
    } else {
      Drupal.behaviors.productBriefShadesV1.rebuildShadesSliderOnActiveFilter(
        $swatchList,
        activeFilters
      );
    }
  });

  $(document).on('grid.reflow', function () {
    $('.js-filter-swatch-slider').each(function () {
      // we initialize the filter carousels only when the item is fully visible else
      // the sliders doesn't load right due to item being hidden
      Drupal.behaviors.productBriefShadesV1.initializeSlick($(this));
    });
  });

  $(document).on('grid.reset.filters', function () {
    // remove all the filter sliders on filter reset
    $('.js-filter-swatch-slider', $(this)).remove();
    $('.slick-slider', $(this)).removeClass('hidden');
    if (Drupal.behaviors.productBriefShadesV1.useGridLayout) {
      var $shadesContainer = $('.js-product-brief-shades-v1', $(this));
      var $swatchLists = $('.js-product-brief-shades__grid', $shadesContainer);

      $swatchLists.each(function () {
        var $swatchList = $(this);

        Drupal.behaviors.productBriefShadesV1.initializeGrid($swatchList);
      });
    }
  });

  $(document).on('grid.carousel.visible', function (e, data) {
    // If the grid is set as infinite carousel we need to listen for the load event
    // and initialize the swatches slick from the cloned items as they only get the
    // html from the items they clone, they don't get the slick functionality
    var $carousel = data.gridCarousel;
    var $clonedSlides = $carousel.find('.slick-cloned.js-mpp-grid-item');

    if ($clonedSlides.length > 0) {
      $clonedSlides.each(function () {
        var $clonedSlide = $(this);
        var $swatchList = $('.js-product-brief-shades__grid:not(.reloaded)', $clonedSlide);

        if ($swatchList.length > 0) {
          Drupal.behaviors.productBriefShadesV1.reinitShadeCarousel($swatchList);
        }
      });
    }
  });

  $(document).on('grid.carousel.lazyloaded', function (e, data) {
    var $carousel = data.gridCarousel;
    var selector = data.selector || '.js-product-grid-item';
    var $swatchListActiveSlides = $(
      selector + ' .js-product-brief-shades__grid:not(.reloaded)',
      $carousel
    );

    if ($swatchListActiveSlides.length > 0) {
      $swatchListActiveSlides.each(function () {
        Drupal.behaviors.productBriefShadesV1.reinitShadeCarousel($(this));
      });
    }
  });

  $('.js-product-brief-shades-item-link').on('keydown', function (e) {
    if (site.getKeycode(e) === 13) {
      $(this).trigger('click');
    }
  });

  Drupal.behaviors.productBriefShadesV1 = {
    isMobile: false,
    useGridLayout: false,
    attached: false,
    gridLayout: {
      itemsPerRow: 6
    },
    attach: function (context) {
      var self = this;
      var $shadesContainer = $('.js-product-brief-shades-v1', context);

      if (self.attached) {
        return;
      }
      self.attached = true;
      var bp = '';

      self.isMobile = (bp = Unison.fetch.now().name) === 'small';
      self.init($('.js-product-brief-shades__grid', $shadesContainer));
      self.setEvents(context);

      Unison.on('change', function (cbp) {
        self.isMobile = cbp.name === 'small';
        if (bp !== cbp.name && (cbp.name === 'small' || bp === 'small')) {
          self.init($('.js-product-brief-shades__grid', $shadesContainer));
          self.setEvents(context);
          bp = cbp.name;
        }
      });
      $(document).on('product.shadeCarousel.visible', function (e, data) {
        var $swatchList = data.swatchList;
        if ($swatchList.length > 0) {
          self.reinitShadeCarousel($swatchList);
        }
      });
    },

    setEvents: function (context) {
      var self = this;

      // only bind the visible items, the hidden will get rebound on parent show / load
      $('.js-product-brief-shades-item-link:visible', context)
        .once('js-shdes-carousel-link')
        .on('click', function (e) {
          e.preventDefault();
          self.selectShade($(this));
        });
      $('.js-product-brief-shades__toggle-grid', context)
        .once()
        .on('click', function () {
          self.toggleGrid($(this));
        });
      // fail check for mixitup grid, the slick doesn't load properly if the parent
      // of the slick is a mixitup item and the grid loads while window is out of focus
      // so we have to reinit all of them
      $('.js-product-results-wrapper .mpp-grid__wrapper', context)
        .once('js-product-shades-loaded')
        .on('mixEnd', function () {
          var $grid = $(this);

          if ($grid.hasClass('js-product-shades-loaded-processed')) {
            // run only once per page load
            $grid.removeClass('js-product-shades-loaded-processed');
            var $swatchLists = $('.js-product-brief-shades__grid', $grid);

            $swatchLists.each(function () {
              self.initializeSlick($(this));
            });
          }
        });
    },

    toggleGrid: function ($elem) {
      var $shadeList = $elem.closest('.js-product-brief-shades-v1');
      var $gridItem = $elem.closest('.js-product-grid-item');
      var toggledState = $shadeList.attr('aria-expended') === 'false' ? true : false;
      var $innerGrid = $('.js-product-brief-shades__grid-inner', $shadeList);

      // need to reset the height set on grid item by slick at initial load to allow the item to grow
      $gridItem.once('shades-grid-layout-reset-parent-height').css({ height: '' });
      $shadeList.attr('aria-expended', toggledState);
      if (toggledState) {
        $innerGrid.css({ 'margin-top': 0 });
      } else {
        $innerGrid.css({ 'margin-top': $innerGrid.data('marginTop') });
      }
    },

    selectShade: function ($swatchLink) {
      var self = this;
      var selectedSkuBaseId = $swatchLink.attr('data-sku-base-id');
      var $productEl = $swatchLink.closest('.js-product');

      $productEl.trigger('product.skuSelect', selectedSkuBaseId);
      if ($swatchLink.hasClass('selected')) {
        $swatchLink.attr({
          'aria-selected': 'true',
          'aria-describedby': 'js-shade-updating-message'
        });
      }
      self.setSelectedRow($swatchLink.closest('.js-product-brief-shades__grid'));
    },

    init: function ($swatchLists) {
      var self = this;

      if (!self.attached) {
        // wait for attach, product.init triggers before attach and breaks the logic
        return;
      }
      self.useGridLayout =
        self.isMobile &&
        $swatchLists.closest('.js-mpp-grid').hasClass('js-mpp-grid--shade-grid-layout');
      $swatchLists.each(function () {
        var $swatchList = $(this);
        var $swatches = $('.js-product-brief-shades-item-link', $swatchList);
        // Select first sku
        var $contextProductEl = prodcat.ui.getContextProductEl($swatchList);

        if (!$contextProductEl.data('default-shade-unselected')) {
          self.selectFirstSku($swatchList);
        }

        // add gray border to light shades (lighter than  R: 233 G: 223 B: 245 or hex #E9DFF5)
        $swatches.each(function () {
          var $swatchLink = $(this);
          var rgbStr = $swatchLink.css('backgroundColor');
          var matches = rgbStr.match(/(\d*),\s(\d*),\s(\d*)/);

          // IE8 returns a hex value; other browsers return rgb. We're not drawing outlines in IE8 anyway, so return.
          if (!matches) {
            return false;
          }
          var red = parseInt(matches[1]);
          var green = parseInt(matches[2]);
          var blue = parseInt(matches[3]);

          if (red > 233 && green > 223 && blue > 245) {
            $swatchLink.addClass('light-shade');
          }
        });
        self.initializeSlick($swatchList);
        $swatchList.on('resetSwatchList', function () {
          self.resetSwatchList($swatchList);
        });
        $swatchList.on('selectSwatch', function (e, skuBaseId) {
          self.selectSku($swatchList, skuBaseId);
        });
      });
    },

    inventoryCheck: function ($swatchLink, skuData) {
      var self = this;
      var classPrefix = 'shade-inventory-status--';

      if (!!skuData) {
        var loadedInventoryStatus = parseInt($swatchLink.data('inventoryStatus'));
        var L2InventoryStatus = parseInt(skuData.INVENTORY_STATUS);
        var $shadeItem = $swatchLink.closest('.js-product-brief-shades-item');
        var $sliderList =
          $shadeItem.closest('.slick-track').length > 0
            ? $shadeItem.closest('.slick-track')
            : $shadeItem.closest('.js-product-brief-shades__grid');
        var enableReorder = $sliderList.find('.js-product-brief-shades-item').length > 1;

        // If the updated L2 is the same status. Nothing to update.
        if (!_.isUndefined(loadedInventoryStatus) && L2InventoryStatus === loadedInventoryStatus) {
          if (enableReorder && L2InventoryStatus !== 1) {
            self.moveAtTheEnd($sliderList, $shadeItem);
          }

          return;
        }

        if (!!loadedInventoryStatus) {
          $swatchLink.removeClass(classPrefix + loadedInventoryStatus);
        }
        $swatchLink
          .addClass(classPrefix + L2InventoryStatus)
          .data('inventoryStatus', L2InventoryStatus)
          .attr('data-inventory-status', L2InventoryStatus);
        if (enableReorder && L2InventoryStatus !== 1) {
          self.moveAtTheEnd($sliderList, $shadeItem);
        }
      }
    },

    moveAtTheEnd: function ($shadesContainer, $shadeItem) {
      $shadesContainer.append($shadeItem);
    },

    initializeGrid: function ($swatchList) {
      var self = this;

      if (!self.useGridLayout) {
        return;
      }
      self.resetSwatchListLayout($swatchList);
      var $shadesContainer = $swatchList
        .closest('.js-product-brief-shades-v1')
        .attr('aria-expended', false);
      var $shades = $('.js-product-brief-shades-item', $swatchList);

      $swatchList.addClass('product-brief-shades__grid--layout');
      $shades.removeClass('hidden');
      self.setInnerGrid($shadesContainer, $swatchList);
      self.gridLayoutToggleBtns($shadesContainer);
      self.setEvents($swatchList);
    },

    resetSwatchListLayout: function ($swatchList) {
      var self = this;
      var $innerGrid = $();

      if ($swatchList.hasClass('slick-initialized') && self.useGridLayout) {
        $swatchList.unslick();
      }
      if ($swatchList.hasClass('product-brief-shades__grid--layout') && !self.useGridLayout) {
        $swatchList.removeClass('product-brief-shades__grid--layout');
        $innerGrid = $('.js-product-brief-shades__grid-inner', $swatchList);
        $innerGrid
          .closest('.js-product-brief-shades-v1')
          .removeClass('grid-inner-wrapper-processed');
        if ($innerGrid.length > 0) {
          $innerGrid.detach().find('.js-product-brief-shades-item').appendTo($swatchList);
        }
      }
    },

    setInnerGrid: function ($shadesContainer, $swatchList) {
      var self = this;
      var $shades = $();
      var $selectedShade = $('.js-product-brief-shades-item-link.selected', $swatchList).closest(
        '.js-product-brief-shades-item'
      );
      var shadeHeight = $swatchList.data('swatchHeight') || 0;
      var shadeInnerHeight = 0;

      $shadesContainer.once('grid-inner-wrapper', function () {
        $shades = $('.js-product-brief-shades-item', $swatchList);
        $shades.wrapAll(
          '<div class="product-brief-shades__grid-inner js-product-brief-shades__grid-inner" />'
        );

        shadeInnerHeight = $selectedShade
          .find('.js-product-brief-shades-item-link')
          .outerHeight(true);
        shadeHeight =
          $selectedShade.outerHeight(true) - $selectedShade.outerHeight(false) + shadeInnerHeight;
        $swatchList.data('swatchHeight', shadeHeight);
      });
      $('.js-product-brief-shades__grid-inner', $shadesContainer)
        .data('marginTop', '0')
        .css({ 'margin-top': '' });
      if (shadeHeight > 0) {
        self.setSelectedRow($swatchList);
      }
    },

    checkSelected: function ($swatchList) {
      var self = this;
      var $selectedShade = $('.js-product-brief-shades-item-link.selected', $swatchList);

      if (!$selectedShade.is(':visible')) {
        $selectedShade.removeClass('selected');
        self.selectFirstSku($swatchList);
      }
    },

    setSelectedRow: function ($swatchList) {
      var self = this;

      if (!self.useGridLayout) {
        return;
      }
      var $selectedShade = $('.js-product-brief-shades-item-link.selected', $swatchList).closest(
        '.js-product-brief-shades-item'
      );
      var $visibleShades = $('.js-product-brief-shades-item:not(.hidden)', $swatchList);
      var $innerGrid = $('.js-product-brief-shades__grid-inner', $swatchList);
      var selectedIndex = $visibleShades.index($selectedShade);

      if (selectedIndex === -1) {
        // no need to continue if there is no shade selected
        return;
      }
      var selectedRow = 0;
      var margin = 0;
      var isGridExpended =
        $swatchList.closest('.js-product-brief-shades-v1').attr('aria-expended') === 'true';
      var shadeHeight = $swatchList.data('swatchHeight');

      if (selectedIndex > self.gridLayout.itemsPerRow - 1) {
        selectedRow = parseInt(selectedIndex / self.gridLayout.itemsPerRow);
        margin = selectedRow * shadeHeight;
        $innerGrid.data('marginTop', -margin + 'px');
        if (!isGridExpended) {
          $innerGrid.css({ 'margin-top': -margin + 'px' }).data('marginTop', -margin + 'px');
        }
      } else if ($innerGrid.data('marginTop') !== '0') {
        $innerGrid.css({ 'margin-top': '' }).data('marginTop', '0');
      }
    },

    reinitShadeCarousel: function ($swatchList) {
      var self = this;
      var $swatches = $(
        '.js-product-brief-shades-item:not(.slick-cloned)',
        $swatchList
      ).removeClass('slick-slide');

      $swatchList.empty().removeClass('slick-initialized').append($swatches).addClass('reloaded');
      // make sure the event get's added back, only the once class get's transfered on clone losing the event itself
      $('.js-product-brief-shades-item-link', $swatchList).removeClass(
        'js-shdes-carousel-link-processed'
      );
      self.setEvents($swatchList);
      self.initializeSlick($swatchList);
    },

    initializeSlick: function ($swatchList) {
      var self = this;

      if (self.useGridLayout) {
        self.initializeGrid($swatchList);

        return;
      }
      self.resetSwatchListLayout($swatchList);
      var $slider = $swatchList;
      var initialSlide = $slider.data('initialSlide') || 0;
      var slidesCount = 7;
      var totalSlides = $swatchList.find('.js-product-brief-shades-item').length;
      var maxInitialAllowed = totalSlides - slidesCount;

      if (initialSlide > maxInitialAllowed && slidesCount < totalSlides) {
        // need to grab the closest smallest divider to load the slider correctly
        // this should not be required if the slick library will update to latest version
        initialSlide = parseInt(initialSlide / slidesCount + 1) * slidesCount - slidesCount;
      } else if (slidesCount >= totalSlides) {
        // reset when the slider has totalSlides the show limit
        // otherwise it hides the slides before the initial one
        initialSlide = 0;
      }
      var settings = {
        rtl: site.direction.isRTL,
        infinite: false,
        autoplay: false,
        centerMode: false,
        slidesToShow: slidesCount,
        slidesToScroll: slidesCount,
        initialSlide: initialSlide,
        swipe: true,
        onInit: function (_slick) {
          _slick.$slider.trigger('init', _slick);
        },
        onBeforeChange: function (_slick, _currentSlide, _nextSlide) {
          // _currentSlide = The slideIndex the carousel shows first ( this is not really the slide that is active)
          // _nextSlide = The slideIndex the carousel will land on after animation is done
          _slick.$slider.trigger('beforeChange', _slick, _currentSlide, _nextSlide);
        },
        onAfterChange: function (_slick, _curentSlide) {
          _slick.$slider.trigger('afterChange', _slick, _curentSlide);
        },
        onSetPosition: function (_slick) {
          _slick.$slider.trigger('afterSetPosition', _slick);
        },
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        slide: '.js-product-brief-shades-item'
      };

      function _slick_slider() {
        if (!$slider.hasClass('slick-initialized')) {
          return $slider.slick(settings);
        }
      }
      _slick_slider();
      $slider.on('afterSetPosition', function (e, _slick) {
        if (_slick.slideWidth) {
          if (totalSlides === 1 && !_slick.$slideTrack.hasClass('js-forced-width')) {
            var maxWidth = $(_slick.$slides[0])
              .find('.js-product-brief-shades-item-link')
              .outerWidth(true);

            _slick.$slideTrack.css({ width: maxWidth }).addClass('js-forced-width');
          }
          _slick.$slides.removeClass('hidden');
          // the initial setRvents bind only to visible items
          self.setEvents();
        }
      });
      if (
        Drupal.settings.global_variables &&
        Drupal.settings.global_variables.cr22 &&
        $slider.find('.slick-prev, .slick-next').length === 0
      ) {
        $slider.addClass('no-arrows');
      }
    },

    rebuildShadesGridOnActiveFilter: function ($swatchList, activeFilters) {
      var self = this;
      var $shadesContainer = $();
      var $shadesContainers = $();
      var filterString = '';
      var $activeShades = $();

      if (!$.isEmptyObject(activeFilters) && activeFilters.skuFilters.length > 0) {
        filterString = activeFilters.skuFilters.join(', ');
        $activeShades = $swatchList.filter(filterString);
        $swatchList.removeClass('hidden').not($activeShades).addClass('hidden');
        $shadesContainers = $activeShades.closest('.js-product-brief-shades-v1');
        $shadesContainers.each(function () {
          $shadesContainer = $(this);
          self.checkSelected($shadesContainer);
          self.gridLayoutToggleBtns($shadesContainer);
        });
      }
    },

    gridLayoutToggleBtns: function ($shadesContainer) {
      var self = this;
      var $visibleShades = $('.js-product-brief-shades-item:not(.hidden)', $shadesContainer);
      var $toggleGridBtns = $('.js-product-brief-shades__toggle-grid-container', $shadesContainer);

      if ($visibleShades.length > self.gridLayout.itemsPerRow) {
        $toggleGridBtns.removeClass('hidden');
      } else {
        $toggleGridBtns.addClass('hidden');
      }
    },

    rebuildShadesSliderOnActiveFilter: function ($swatchList, activeFilters) {
      $('.slick-slider', $swatchList).removeClass('hidden');
      if ($swatchList.length < 1) {
        return null;
      }
      // remove all the filter sliders on filter
      $('.js-filter-swatch-slider').remove();
      if (!$.isEmptyObject(activeFilters) && activeFilters.skuFilters.length > 0) {
        var filterString = activeFilters.skuFilters.join(', ');
        var $sliders = $swatchList.filter(filterString).closest('.slick-slider');

        $.each($sliders, function () {
          if (!$(this).hasClass('hidden')) {
            $(this).addClass('hidden');
            Drupal.behaviors.productBriefShadesV1.initializeFilterSlick(
              $(this),
              $(this).find($swatchList).filter(filterString)
            );
          }
        });
      } else {
        $swatchList.removeClass('hidden');
      }
    },

    initializeFilterSlick: function ($slider, $slides) {
      var self = this;

      $slides = $slides.clone(true).addClass('hidden');

      var $sliderFilterClone = $slider
        .clone(true)
        .unslick()
        .removeClass('slick-initialized')
        .addClass('js-filter-swatch-slider');

      $sliderFilterClone.data('initialSlide', 0).attr('data-initial-slide', 0);
      // clone the original slider and repopulate only with
      // the shades that match the filter
      // we remove the original slick html
      $sliderFilterClone.find('.slick-list').remove();
      $sliderFilterClone.find('.slick-prev').remove();
      $sliderFilterClone.find('.slick-next').remove();
      $slides.css({ width: '' });
      $sliderFilterClone.append($slides);
      $sliderFilterClone.removeClass('hidden');

      if ($slider.parent().find('.js-filter-swatch-slider').length < 1) {
        // clone doesn't keep the slick from the object so we have to
        // initialize the slider on the newly created slider
        $sliderFilterClone.insertAfter($slider);
        self.selectShade($slides.eq(0));
      }
    },

    selectFirstSku: function ($swatchList) {
      var self = this;
      // based on the list visibility we select the firstSKu
      var isVisible = $swatchList.is(':visible');
      var $links = isVisible
        ? $('.js-product-brief-shades-item-link:visible', $swatchList)
        : $('.js-product-brief-shades-item-link', $swatchList);
      var firstSkuBaseId = '';
      var $firstSelectedShade = $();
      var prodId = $links.data('product-id');
      var $selectedSlide = $();

      // return if any already selected ie. Shade Router
      if ($links.hasClass('selected')) {
        return null;
      }
      var $swatchLi = $('.js-product-brief-shades-item', $swatchList);

      if ($swatchLi.hasClass('js-default-sku-select_' + prodId)) {
        if (isVisible) {
          $firstSelectedShade = $('.js-default-sku-select_' + prodId, $swatchList).is(':visible')
            ? $('.js-default-sku-select_' + prodId, $swatchList)
            : $links.first();
        } else {
          $firstSelectedShade = $('.js-default-sku-select_' + prodId, $swatchList);
        }
        firstSkuBaseId = $firstSelectedShade.data('sku-base-id');
      } else {
        $firstSelectedShade = $links.first();
        firstSkuBaseId = $firstSelectedShade.data('sku-base-id');
      }
      if (!!firstSkuBaseId) {
        $selectedSlide = $(
          '.js-product-brief-shades-item-link[data-sku-base-id="' + firstSkuBaseId + '"]',
          $swatchList
        ).addClass('selected');
        self.setSlickInitialSlide($swatchList, $selectedSlide);
        self.selectShade($firstSelectedShade);
      }
    },

    selectSku: function ($swatchList, skuBaseId) {
      var self = this;

      $swatchList.data('sku-base-id', skuBaseId);
      var $links = $('.js-product-brief-shades-item-link', $swatchList);

      $links.removeClass('selected');
      var $selectedSwatch = $(
        ".js-product-brief-shades-item-link[data-sku-base-id='" + skuBaseId + "']",
        $swatchList
      ).addClass('selected');

      self.setSlickInitialSlide($swatchList, $selectedSwatch);
    },

    setSlickInitialSlide: function ($swatchList, $selectedSlide) {
      var selectedIndex = $('.js-product-brief-shades-item-link', $swatchList).index(
        $selectedSlide
      );

      if (selectedIndex > 0) {
        $swatchList.data('initialSlide', selectedIndex).attr('data-initial-slide', selectedIndex);
      }
    },

    resetSwatchList: function ($swatchList) {
      var $links = $('.js-product-brief-shades-item-link', $swatchList);

      $links.removeClass('selected');
    }
  };
})(jQuery);
